var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "main" }, [
    _c(
      "div",
      { staticClass: "container base-page d-flex" },
      [
        _c(
          "b-row",
          { staticClass: "h-100 align-items-center my-auto" },
          [
            _c("h1", {
              staticClass: "w-100 text-center",
              domProps: { innerHTML: _vm._s(_vm.$t("pages.home.title")) },
            }),
            _vm._v(" "),
            _c(
              "b-card-group",
              { staticClass: "my-5", attrs: { deck: "" } },
              _vm._l(_vm.posts, function (post, index) {
                return _c(
                  "b-card",
                  {
                    key: `post-${index}`,
                    staticClass:
                      "home-card animate__animated animate__slideInUp",
                    attrs: { "body-class": "text-center p-0" },
                    on: {
                      click: function ($event) {
                        return _vm.$router.push(post.link)
                      },
                    },
                  },
                  [
                    _c(
                      "b-card-body",
                      [
                        _c("b-avatar", {
                          staticClass: "align-center",
                          attrs: {
                            src: post.thumb,
                            size: "100",
                            variant: "light",
                          },
                        }),
                        _vm._v(" "),
                        _c("h2", { staticClass: "nav-link active my-3" }, [
                          _vm._v(_vm._s(post.title)),
                        ]),
                        _vm._v(" "),
                        _c("p", {
                          domProps: { innerHTML: _vm._s(post.subtitle) },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                )
              }),
              1
            ),
          ],
          1
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }