export const dataPreloadMixin = {
  methods: {
    async getTeamWeeks() {
      await this.$store.dispatch('fetchTeamWeeks')
    },
    async getVmbData() {
      await this.$store.dispatch('fetchVmbAllData')
    },
    async getVmbObservationData() {
      try {
        await this.$store.dispatch('fetchVmbObservationData')
      } catch (e) {
        this.$store.dispatch('setError', e.response.statusText)
      }
    },
    async getVmbChartData() {
      await this.$store.dispatch('fetchVmbChartData')
    },
    async updateVmbObservationStatus(obsUpdate) {
      try {
        await this.$store.dispatch('updateVmbObservationStatus', obsUpdate).then(response => {
          this.$notify(response.status, 'Item updated', response.message, {
            duration: 3000,
            permanent: false
          })
        })
      } catch (e) {
        console.log(e)
      }
    },
    async getVmbStatus() {
      try {
        await this.$store.dispatch('fetchVmbStatus')
      } catch (e) {
        console.log(e.response)
      }
    }
  }
}
